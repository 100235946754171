import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/users",
    name: "Users",
    component: () => import(/* webpackChunkName: "admin/users" */ "../views/Users.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/voucher",
    name: "Voucher",
    component: () => import(/* webpackChunkName: "admin/voucher" */ "../views/Voucher.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/organisation",
    name: "AdminOrganisations",
    component: () => import(/* webpackChunkName: "admin/organisation" */ "../views/AdminOrganisations.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/organisation/:id",
    name: "AdminOrganisationEdit",
    component: () => import(/* webpackChunkName: "admin/organisation/:id" */ "../views/AdminOrganisationEdit.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/registration/:hash",
    name: "LinkRegistration",
    component: () => import(/* webpackChunkName: "/registration/:hash" */ "../views/LinkRegistration.vue"),
  },
  {
    path: "/registration",
    name: "Registration",
    component: () => import(/* webpackChunkName: "/registration" */ "../views/Registration.vue"),
  },
  {
    path: "/organisation/:id",
    name: "Organisation",
    component: () => import(/* webpackChunkName: "organisation/:id" */ "../views/Organisation.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/organisation/:id/import",
    name: "Import Worker",
    component: () => import(/* webpackChunkName: "organisation/:id/import" */ "../views/ImportWorker.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/test",
    name: "Test",
    component: () => import(/* webpackChunkName: "/test" */ "../views/Test.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/test/organisation/:id",
    name: "TestOrganisation",
    component: () => import(/* webpackChunkName: "/test/organisation/:id" */ "../views/TestOrganisation.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/booking",
    name: "TestBooking",
    component: () => import(/* webpackChunkName: "booking" */ "../views/TestBooking.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/account",
    name: "Account",
    component: () => import("../views/Account.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/verification/:token",
    name: "Appointment Verification",
    component: () => import(/* webpackChunkName: "verification" */ "../views/AppointmentValidation.vue"),
  },
  {
    path: "/password-reset/:token",
    name: "Password reset",
    component: () => import("../views/PasswordReset.vue"),
  },
  {
    path: "/impressum",
    name: "Impressum",
    component: () => import("../views/Impressum.vue"),
  },
  {
    path: "/policy",
    name: "Policy",
    component: () => import("../views/Policy.vue"),
  },
  {
    path: "/agb",
    name: "AGB",
    component: () => import("../views/Agb.vue"),
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

export default router;
