import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import VuePapaParse from "vue-papa-parse";
import VueGtag from "vue-gtag";

Vue.config.productionTip = false;

Vue.use(VueGtag, {
  config: { id: "GTM-M8JR568" },
  bootstrap: false,
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

Vue.prototype.$http = axios;

Vue.use(VuePapaParse);

// Set axios defaults
Vue.prototype.$http.defaults.baseURL = process.env.VUE_APP_API;
Vue.prototype.$http.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
Vue.prototype.$http.defaults.headers.common["Access-Control-Allow-Methods"] = "GET,PUT,POST,DELETE,PATCH,OPTIONS";

const token = localStorage.getItem("token");

if (token) {
  Vue.prototype.$http.defaults.headers.common["x-token"] = token;
  Vue.prototype
    .$http({
      method: "get",
      url: "/user/me",
    })
    .then((response) => {
      store.dispatch("getUser", response.data).catch((err) => {
        console.log(err);
      });
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
      store.dispatch("logout");
    });
}
