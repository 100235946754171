<template>
  <v-app>
    <v-app-bar app color="white" elevation="3">
      <v-container>
        <div class="d-flex flex-row align-center">
          <v-toolbar-title @click="$router.push('/')">
            <v-img src="../src/assets/SMARTmedSolutions-Logo-01.png" contain width="100"></v-img>
          </v-toolbar-title>

          <v-spacer></v-spacer>
          <v-menu v-if="isLoggedIn" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" text>
                Menü
                <v-icon right> mdi-menu </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="$router.push('/')" link>
                <v-list-item-title>Dashboard</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="user.isSuperAdmin" @click="$router.push('/admin/users')" link>
                <v-list-item-title>Benutzerverwaltung</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="user.isSuperAdmin" @click="$router.push('/admin/organisation')" link>
                <v-list-item-title>Firmenverwaltung</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="user.isSuperAdmin" @click="$router.push('/admin/voucher')" link>
                <v-list-item-title>Gutscheinverwaltung</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="user.isSuperAdmin || user.isMedical" @click="$router.push('/test')" link>
                <v-list-item-title>Medizinische Verwaltung</v-list-item-title>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item @click="$router.push('/account')" link>
                <v-list-item-title>Account</v-list-item-title>
              </v-list-item>
              <v-list-item @click="logout" link>
                <v-list-item-title>Abmelden</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-container>
    </v-app-bar>

    <v-main><router-view></router-view></v-main>

    <cookie-law theme="dark-lime" buttonDecline="false" v-on:accept="activateTracking()"></cookie-law>

    <v-footer color="grey lighten-4">
      <v-container>
        <div class="d-flex flex-md-row flex-column-reverse align-md-end flex-wrap text-h8 pb-6 pt-2">
          <div class="pr-16">
            <v-img class="my-4" src="../src/assets/SMARTmedSolutions-Logo-01.png" contain width="50"></v-img>
            SMARTmedSolutions GmbH<br />
            Max-Ernst-Str. 4<br />
            D-50354 Hürth (bei Köln)<br />
            info@smartmedsolutions.de
          </div>
          <div class="pl-0 pl-md-16 pt-8 pt-md-0">
            <a href="https://smartmed-test.de/tests/" style="text-decoration: none">Tests</a><br />
            <a href="https://smartmed-test.de/ueber-uns/" style="text-decoration: none">Über uns</a><br />
            <a style="text-decoration: none" @click="$router.push('/impressum')">Impressum</a><br />
            <a style="text-decoration: none" @click="$router.push('/policy')">Datenschutz</a><br />
            <a style="text-decoration: none" @click="$router.push('/agb')">AGB</a><br />
          </div>
        </div>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
import CookieLaw from "vue-cookie-law";
import { bootstrap } from "vue-gtag";
export default {
  name: "App",

  components: { CookieLaw },
  data: () => ({
    drawer: false,
  }),

  computed: {
    isLoggedIn: function() {
      return this.$store.getters.isLoggedIn;
    },

    user() {
      return this.$store.state.user;
    },
  },

  methods: {
    logout: function() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    },
    activateTracking: function() {
      bootstrap().then(() => {
        // all done!
      });
    },
  },
};
</script>
