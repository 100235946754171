import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: { status: "", token: localStorage.getItem("token") || "", user: {} },
  mutations: {
    auth_request(state) {
      state.status = "loading";
    },

    auth_success(state, data) {
      state.status = "success";
      state.token = data.token;
      state.user = data.user;
    },

    set_user(state, user) {
      state.user = user;
    },

    logout(state) {
      state.status = "";
      state.token = "";
    },
  },
  actions: {
    login({ commit }, data) {
      return new Promise((resolve) => {
        commit("auth_request");
        localStorage.setItem("token", data.token);
        axios.defaults.headers.common["x-token"] = data.token;
        commit("auth_success", data);
        resolve();
      });
    },

    logout({ commit }) {
      return new Promise((resolve) => {
        commit("logout");
        localStorage.removeItem("token");
        delete axios.defaults.headers.common["x-token"];
        resolve();
      });
    },

    getUser({ commit }, data) {
      return new Promise((resolve) => {
        commit("set_user", data);
        resolve();
      });
    },
  },
  modules: {},
  getters: {
    isLoggedIn: (state) => !!state.token,
    authStatus: (state) => state.status,
  },
});
